import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

type GetImgProps = {
  src: string
  alt: string
}

const GetImg = ({ src, alt }: GetImgProps) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
        edges {
          node {
            extension
            relativePath
            childrenImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
    }
  `);

  const foundFile = data.allFile.edges.find(image => image.node.relativePath === src)
  const image = foundFile.node.childrenImageSharp[0].gatsbyImageData

  return (
    <GatsbyImage image={image} alt={alt} />
  )
}

export default GetImg

