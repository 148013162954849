import { merge } from "theme-ui";
import { tailwind } from "@theme-ui/presets";

// 60B40 TEXT
// FFFFFF BACKGROUND
// 6729B6 PRIMARY
// DD5B9F SECONDARY
// B5A9BB MUTED
// F5ACF6 HIGHLIGHT
// 98FFDA ACCENT

const colors = {
  primary: "#6729B6",
  background: "#FFFFFF",
  muted: "#B5A9BB",
  darkBackground: "#130918",
  secondary: "#DD5B9F",
  highlight: "#F5ACF6",
  accent: "#98FFDA",
  text: "#2C2136",

  logoFill: "#DD5B9F",
  logoFillHover: "#68007A",
  logoOutline: "#68007A",
};

export default merge(tailwind, {
  config: {
    initialColorModeName: `light`,
    useCustomProperties: true,
    useColorSchemeMediaQuery: false,
  },
  colors: {
    primary: colors.primary,
    btnColor: colors.secondary,
    secondary: colors.secondary,
    accent: colors.accent,
    textAccent: colors.darkBackground,
    text: colors.text,
    highlight: colors.highlight,
    autofillText: colors.text,
    toggleIcon: tailwind.colors.black,

    logoFill: colors.logoFill,
    logoFillHover: colors.logoFillHover,
    logoOutline: colors.logoOutline,
    modes: {
      dark: {
        text: tailwind.colors.white,
        logoOutline: tailwind.colors.white,
        primary: colors.secondary,
        secondary: colors.primary,
        btnColor: colors.secondary,
        background: colors.darkBackground,
        textMuted: colors.muted,
        highlight: colors.highlight,
        toggleIcon: tailwind.colors.white,
      },
    },
  },
  fontSizes: [
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "1.875rem",
    "2.25rem",
    "3rem",
    "4rem",
    "4.5rem",
    "5rem",
    "5.5rem",
    "6rem",
  ],
  layout: {
    footer: {
      textAlign: `center`,
      // backgroundColor: `secondary`,
      // position: `fixed`,
      // bottom: `0`,
      // left: `0`,
      // width: `100%`,
      display: `block`,
      color: `textMuted`,
      px: [2, 3],
      py: [2, 3],
    },
    header: {
      px: [3, 4],
      py: [2, 3],
      fontSize: 2,
      display: `flex`,
      alignItems: `center`,
      flexWrap: `wrap`,
    },
    contact: {
      px: [3, 4],
      py: [2, 0],
    },
    main: {
      position: `relative`,
    },
    container: {
      maxWidth: `5xl`,
      padding: 4,
    },
  },
  styles: {
    root: {
      color: `text`,
      backgroundColor: `background`,
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
    },
    p: {
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      color: `text`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
    a: {
      color: `primary`,
      "&:hover": {
        color: `secondary`,
      },
    },

    h1: {
      fontSize: [5, 6],
      mt: 2,
    },
    h2: {
      fontSize: [4, 5],
      mt: 2,
    },
    h3: {
      fontSize: [3, 4],
      mt: 3,
    },
    h4: {
      fontSize: [2, 3],
    },
    h5: {
      fontSize: [1, 2],
    },
    h6: {
      fontSize: 1,
      mb: 2,
    },

    hero: {
      fontSize: [6, 7, 10],
      color: `primary`,
      textAlign: `center`,
      pb: 1,
    },

    heroDesc: {
      fontSize: [2, 3],
    },
  },
  buttons: {
    toggle: {
      color: `secondary`,
      border: `none`,
      backgroundColor: `text`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 3,
      py: 2,
      ml: 3,
    },
    contact: {
      color: `background`,
      backgroundColor: `primary`,
      border: `none`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 3,
      py: 2,
      ml: 0,
      transition: `all 0.25s ease`,
      "&:hover": {
        backgroundColor: `highlight`,
      },
    },
  },
});
